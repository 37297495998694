import React from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper 
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ReportOutput = ({ report }) => {
  const formatTableContent = (content) => {
    try {
      const data = JSON.parse(content);
      return (
        <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="test cases table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                {Object.keys(data[0]).map((header) => (
                  <TableCell 
                    key={header}
                    sx={{ 
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } catch {
      return (
        <Box sx={{ 
          whiteSpace: 'pre-wrap', 
          backgroundColor: '#f5f5f5',
          p: 2,
          borderRadius: 1
        }}>
          {content}
        </Box>
      );
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Generated Test Report</Typography>
        <CopyToClipboard text={report}>
          <Button variant="contained">Copy Report</Button>
        </CopyToClipboard>
      </Box>
      {formatTableContent(report)}
    </Box>
  );
};

export default ReportOutput;