import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Typography 
} from '@mui/material';

const ChatInput = ({ setGeneratedReport }) => {
  const [formData, setFormData] = useState({
    question: '',
    requirements: '',
    selectedTest: ''
  });

  const testingTypes = [
    'Black Box Testing',
    'White Box Testing',
    'Gray Box Testing',
    'Unit Testing',
    'System Testing',
    'Compatibility Testing',
    'Performance Testing',
    'Stress Testing',
    'Accessibility Testing'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const promptText = `
      Question: ${formData.question}
      Requirements: ${formData.requirements}
      Testing Type: ${formData.selectedTest}
      
      Please generate a detailed Software Quality Assurance testing report focusing on ${formData.selectedTest} 
      that addresses the above question and requirements. Include specific test cases, expected results, 
      and proper documentation format.
    `;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{
            role: "user",
            content: promptText
          }]
        })
      });

      const data = await response.json();
      setGeneratedReport(data.choices[0].message.content);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ '& > *': { mb: 3 } }}>
      <Box>
        <Typography variant="h6" gutterBottom>
          1. Question
        </Typography>
        <TextField
          name="question"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          placeholder="Enter your main question or problem statement"
          value={formData.question}
          onChange={handleChange}
        />
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          2. Requirements
        </Typography>
        <TextField
          name="requirements"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          placeholder="Enter the specific requirements or specifications"
          value={formData.requirements}
          onChange={handleChange}
        />
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          3. Select Testing Type
        </Typography>
        <FormControl fullWidth>
          <InputLabel>Testing Type</InputLabel>
          <Select
            name="selectedTest"
            value={formData.selectedTest}
            onChange={handleChange}
            label="Testing Type"
          >
            {testingTypes.map((test) => (
              <MenuItem key={test} value={test}>
                {test}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button 
        type="submit" 
        variant="contained" 
        color="primary"
        fullWidth
        size="large"
        disabled={!formData.question || !formData.requirements || !formData.selectedTest}
      >
        Generate Report
      </Button>
    </Box>
  );
};

export default ChatInput;