import React, { useState } from 'react';
import { Container, Box, Typography, Paper } from '@mui/material';
import ChatInput from './components/ChatInput';
import ReportOutput from './components/ReportOutput';

function App() {
  const [generatedReport, setGeneratedReport] = useState(null);

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          404 Not Found
        </Typography>
        
        <Paper sx={{ p: 3, mb: 3 }}>
          <ChatInput setGeneratedReport={setGeneratedReport} />
        </Paper>

        {generatedReport && (
          <Paper sx={{ p: 3 }}>
            <ReportOutput report={generatedReport} />
          </Paper>
        )}
      </Box>
    </Container>
  );
}

export default App;